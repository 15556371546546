import * as React from "react";
import { graphql, PageProps } from "gatsby";

import RecipesTemplate from "@components/recipes/recipePageTemplate";
import { BreadcrumbPageContext } from "@util/types";
import { Query } from "@graphql-types";

interface Props extends PageProps {
  pageContext: BreadcrumbPageContext;
  data: Query;
}

const RecipesAllPage = ({ pageContext, data }: Props) => {
  const { allSanityRecipe, allSanityRecipeTag } = data;
  return (
    <RecipesTemplate
      pageContext={pageContext}
      allSanityRecipe={allSanityRecipe}
      allSanityRecipeTag={allSanityRecipeTag}
      iban={pageContext.iban}
    />
  );
};

export default RecipesAllPage;

export const query = graphql`
  query RecipesAllQuery($iban: String) {
    allSanityRecipeTag(
      sort: { fields: order, order: ASC }
      filter: {
        main: { regions: { elemMatch: { iban: { eq: $iban } } } }
        recipes: {
          elemMatch: { regions: { elemMatch: { iban: { eq: $iban } } } }
        }
      }
    ) {
      ...sanityRecipeTagConnection
    }
    allSanityRecipe(
      filter: { regions: { elemMatch: { iban: { eq: $iban } } } }
    ) {
      ...sanityRecipeConnection
    }
  }
`;
